/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeng/resources/themes/md-light-indigo/theme.css';

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* styles.css */
@layer utilities {
  .safe-top {
    padding-top: env(safe-area-inset-top);
  }

  .safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    height: -webkit-fill-available;
     overflow-y: scroll;
  }

  /* If using React, set height on the root div as well */
  #root {
    height: -webkit-fill-available;
  }

  .screen {
    @apply flex flex-col w-screen h-screen;
    // height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 2rem);
  }

  ion-toolbar {
    --background: var(--ion-color-primary);
    --border-color: var(--ion-color-secondary);
    --border-width: 0px;
    --box-shadow: 0 0 0 0;
    --border-style: none;
  }
}

.action-sheet {
  --background: white;
  --border-color: var(--ion-color-secondary);
  --border-width: 2px;
  --box-shadow: @apply shadow-lg;
  --border-radius: 1rem;
  --box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.action-sheet::part(backdrop) {
  background: white !important;
  /* or any color you want */
}

@layer components {
  .light-button {
    @apply w-full h-14 py-4 rounded-lg shadow border-2 border-gray-800 justify-center items-center inline-flex text-center text-gray-800 text-base font-bold font-['Roboto'] leading-normal tracking-tight mt-4;
  }

  .input {
    @apply w-full h-12 rounded-lg bg-white px-4 py-4 my-2 outline-none text-primary-900 text-sm font-normal font-['Roboto'] leading-4 border border-primary-300;
  }

  .dark-button {
    @apply w-full h-14 py-4 bg-gray-800 rounded-lg shadow justify-center items-center inline-flex text-center text-gray-100 text-base font-bold font-['Roboto'] leading-normal tracking-tight mt-4;
  }

  app-input {
    width: 100%;
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  .sidebar {
    animation: slideIn 0.5s ease-out forwards;
  }
}

:host {
  background-color: #263238;
}

.map-list-item-pop-over {
  --width: 250px;
}

.header-md {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.expand-btn {
  width: 30px;
  height: 30px;
  margin: auto;
  color: #227c9d;
  background-color: #fef9ef;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
}

.expand-btn > span:last-child {
  display: flex;
}

ion-popover {
  --width: 280px;
  --offset-x: -10px;
}

/* Bottom to Top Animation */
@keyframes bottomToTop {
  from {
    transform: translateY(200%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.list-item-animated {
  animation: bottomToTop 0.1s ease forwards;
  will-change: transform, opacity;
}

.p-button {
  font-size: 14px !important;
  padding: 8px;
  @apply text-primary-900 text-sm font-medium font-['Roboto'] capitalize leading-normal bg-white;
}

.p-highlight {
  @apply text-white bg-primary-900;
}

.p-dropdown {
  @apply w-full outline-none bg-white border-dashed border-2 border-primary-300;

  .p-inputtext {
    @apply border-none;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-inputtext {
  @apply w-full p-2 outline-none bg-white border-dashed border-2 border-primary-300;
}

.p-inputnumber-button {
  @apply bg-primary-900 text-white text-sm font-medium font-['Roboto'] capitalize leading-normal;
}

.p-inputwrapper {
  @apply w-full;
  @apply h-10;
}

.p-inputnumber-buttons-horizontal {
  @apply w-full;
}

.p-organizationchart {
  @apply w-full;
}

.p-organizationchart-node-content {
  @apply bg-white border-primary-300 rounded-md text-sm text-primary-900 font-medium font-['Roboto'] capitalize leading-normal h-auto;
}

.p-organizationchart-lines {
  @apply rounded-md;
}

.p-organizationchart-lines .p-organizationchart-line-down {
  @apply border-primary-400 border-solid;
  border-right-width: 1px;
}

.p-organizationchart-lines .p-organizationchart-line-left {
  @apply border-r-primary-400 border-solid;
  border-right-width: 1px;
}

.p-organizationchart-lines .p-organizationchart-line-right {
  @apply border-l-primary-400 border-solid;
  border-left-width: 1px;
}

.p-organizationchart-lines .p-organizationchart-line-top {
  @apply border-t-primary-400 border-solid;
  border-top-width: 1px;
}

tr.p-organizationchart-node-hidden {
  td.p-organizationchart-line-left,
  td.p-organizationchart-line-right,
  td.p-organizationchart-line-top,
  td.p-organizationchart-line-down {
    border: none;
  }
}

.p-node-toggler {
  @apply bg-white shadow-sm rounded-full w-8 h-8 flex items-center justify-center p-0 m-0;
  left: 50%;
  transform: translate(-50%);
}

.org-chart-container {
  @apply w-full flex flex-col items-start justify-start p-4;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar */
}

.num-pad {
  @apply col-span-1 row-span-1 rounded-md w-full h-full border-primary-300 border-2 text-center flex items-center justify-center font-bold text-gray-800 max-w-24 max-h-24;
}

.map-view-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #263238;
}

.badge {
  background: url('assets/icons/svg/functions/badge.svg');
  background-size: 16px 16px;
  /* Set the size of the background image */
  background-position: top right;
  /* Position the background image in the top right corner */
  background-repeat: no-repeat;
}

.partial {
  background: url('assets/icons/svg/functions/partial.svg');
  background-size: 12px 12px;
  background-position: top right;
  background-repeat: no-repeat;
}

.p-inputtext {
  @apply text-primary-900 text-sm font-normal font-['Roboto'] leading-4;
}

.p-inputtext.normal,
.p-password-input {
  @apply border border-solid h-10;
}

.p-inputwrapper {
  input {
    @apply w-full p-2 outline-none bg-white border-solid border border-primary-300;
  }
}

ion-modal {
  --height: 100%;
    --max-width: 600px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

@mixin data-modal {
  --width: fit-content;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding: 1rem;
}

.data-modal {
  @include data-modal;
}

ion-modal::part(backdrop) {
  background: rgb(27, 27, 28) !important;
  opacity: 0.32 !important;
}

.people-group-modal {
  @include data-modal;
  --height: 100%;
  padding: 0.25rem;
}

.p-dropdown-panel,
.p-dropdown-panel .p-dropdown-header {
  background: #ffffff;
}

.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0;
}

ion-modal::part(content) {
  width: 100%;
}

.country-dropdown {
  @apply w-full outline-none bg-white border-solid border border-primary-300 rounded-md h-14 pt-1;

  .p-dropdown {
    @apply border-none pt-1;
  }

  .p-inputtext {
    @apply border-none;
  }
}

:root {
  /** primary **/
  --ion-color-primary: #263238;
  --ion-color-primary-rgb: 38, 50, 56;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #212c31;
  --ion-color-primary-tint: #3c474c;

  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #b0bec5;
  --ion-color-tertiary-rgb: 176, 190, 197;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #9ba7ad;
  --ion-color-tertiary-tint: #b8c5cb;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e40101;
  --ion-color-danger-rgb: 228, 1, 1;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c90101;
  --ion-color-danger-tint: #e71a1a;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-medium: #b0bec5;
  --ion-color-medium-rgb: 176, 190, 197;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #9ba7ad;
  --ion-color-medium-tint: #b8c5cb;

  --ion-color-dark: #263238;
  --ion-color-dark-rgb: 38, 50, 56;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #212c31;
  --ion-color-dark-tint: #3c474c;
}

.node {
  will-change: transform, opacity;
}

.chart-container {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0); /* For Safari */
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform; /* Force GPU rendering */
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d; /* For Safari */
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

body {
  --ion-background-color: #ffffff; /* or your preferred light theme background color */
  --ion-text-color: #000000; /* or your preferred light theme text color */
  // ... set other variables as needed for your light theme
}


.keyboard-is-open {
  padding-bottom: 270px; /* Adjust this value based on your keyboard height */
}

@media (max-height: 400px) {
  .keyboard-is-open {
    padding-bottom: 0;
  }
}


.initial-dialog {
  --with: 400px;
  --max-width: 600px;
}

.pin-modal{
  --max-height: 800px;
}
